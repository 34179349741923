<template>
  <div
    class="sidebar_list f_14"
    :class="{ border_l: borderShow, 'p-15': type == 'three' }"
  >
    <div class="sidebar_title f_16">
      <h2 class="sidebar_title_t" @click="goNavType">{{ searchValue }}</h2>
      <slot name="input"></slot>
    </div>
    <div class="sidebar_one" v-if="type == 'one'">
      <div class="sidebar_one_item" v-for="(item, index) in data" :key="index">
        <div class="sidebar_one_l">
          <img :src="item.novel_cover" :alt="item.novel_name" />
        </div>
        <div class="sidebar_one_m">
          <span class="copy_icon"></span>
          <span class="font_w" @click="goChapterList(item.novel_id)">
            {{ item.novel_name }}
          </span>
          <span class="hot" v-if="item.hot == 1">Hot</span>
          <span class="full" v-if="item.full == 1">Full</span>
          <p class="author">
            <span class="pen_icon"></span>
            <span>{{ item.novel_author }}</span>
          </p>
        </div>
        <div class="sidebar_one_r p-15" v-if="item.last_chapter_name">
          <p
            @click="
              goChapter(
                item.novel_id,
                item.chapter_id,
                item.last_chapter_name,
                item.novel_name
              )
            "
          >
            {{ item.last_chapter_name }}
          </p>
        </div>
        <div class="sidebar_one_chapter" v-if="item.chapter_num">
          <p>
            <span>{{ item.chapter_num }} </span><span><i>Chapters</i></span>
          </p>
        </div>
      </div>
    </div>
    <div class="sidebar_two" v-if="type == 'two'">
      <div class="sidebar_two_item" v-for="(item, index) in data" :key="index">
        <div class="sidebar_two_l p-15" @click="goChapterList(item.novel_id)">
          <span class="rightIcon"></span>
          <span>{{ item.novel_name }}</span>
          <span class="hot" v-if="item.hot">Hot</span>
        </div>
        <div
          class="sidebar_two_r p-15"
          @click="
            goChapter(
              item.novel_id,
              item.chapter_id,
              item.last_chapter_name,
              item.novel_name
            )
          "
        >
          {{ item.last_chapter_name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import penIcon from "@/assets/img/pen.png";
import copyIcon from "@/assets/img/copy.png";
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    borderShow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    type: {
      type: String,
      default: () => {
        return null;
      },
    },
    searchValue: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      penIcon: penIcon,
      copyIcon: copyIcon,
    };
  },
  methods: {
    // 去列表详情
    goNavType() {
      this.$emit("onNavList");
    },
    // 去小说详情
    goChapterList(id) {
      if (this.page == "ArticleList") {
        this.$emit("onChapterList", id);
      } else {
        this.$router.push({
          path: `/articleList/${id}`,
        });
      }
    },
    // 去小说章节
    goChapter(nid, cid, last_name, name) {
      let arrayLocal = JSON.parse(localStorage.getItem("historyList"));
      if (arrayLocal) {
        let isTrue = arrayLocal.some((item) => item.novel_id == nid);
        if (isTrue) {
          arrayLocal.forEach((item) => {
            if (item.novel_id == nid) {
              item.last_chapter_name = last_name;
              item.chapter_id = cid;
            }
          });
        } else {
          arrayLocal.unshift({
            novel_id: nid,
            novel_name: name,
            last_chapter_name: last_name,
            chapter_id: cid,
          });
        }
      } else {
        arrayLocal = [];
        arrayLocal.unshift({
          novel_id: nid,
          novel_name: name,
          last_chapter_name: last_name,
          chapter_id: cid,
        });
      }

      localStorage.setItem("historyList", JSON.stringify(arrayLocal));
      this.$router.push({
        path: `/article/${nid}/${cid}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar_list {
  .sidebar_title {
    cursor: default;
    height: 40px;
    text-transform: uppercase;
    border-bottom: 1px solid #ccc;
    position: relative;
    .sidebar_title_t {
      padding: 0 5px;
    }

    h2,
    span {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #4e4e4e;
    }
  }

  .sidebar_one {
    .sidebar_one_item {
      min-height: 90px;
      border-bottom: 1px dashed #ccc;
      display: table;

      // div {
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }

      .sidebar_one_l {
        padding: 0 5px;
        display: table-cell;
        vertical-align: middle;

        img {
          display: block;
          width: 62px;
          height: 87px;
        }
      }

      .sidebar_one_m {
        color: #3c3c3c;
        width: 100%;
        display: table-cell;
        padding: 0 5px;
        max-width: 203px;
        vertical-align: middle;

        .copy_icon {
          position: relative;
          margin-right: 20px;

          &::after {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            content: " ";
            background: url("~@/assets/img/copy.png") center center no-repeat;
            background-size: 100% 100%;
            width: 14px;
            height: 14px;
          }
        }

        .pen_icon {
          position: relative;
          margin-right: 20px;

          &::after {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            content: " ";
            background: url("~@/assets/img/pen.png") center center no-repeat;
            background-size: 100% 100%;
            width: 14px;
            height: 14px;
          }
        }

        .sidebar_icon {
          width: 16px;
          height: 16px;
          display: block;
          // vertical-align: middle;
          margin-right: 6px;
        }

        .author {
          font-style: italic;
          display: block;
        }

        .gray {
          color: #4e4e4e;
        }

        .font_w {
          color: #3c3c3c;
          text-decoration: none;
          font-weight: bold;
          font-size: 15px;
          word-wrap: break-word;
        }

        .hot {
          border: 1px solid #fd8383;
          color: #fd8383;
          padding: 1px 3px;
          vertical-align: middle;
          margin-left: 5px;
        }

        .full {
          border: 1px solid #86ad86;
          color: #86ad86;
          padding: 1px;
          vertical-align: middle;
          margin-left: 5px;
        }
      }
      .sidebar_one_chapter {
        max-width: 100px;
        min-width: 100px;
        color: #14425d;
        font-weight: 600;
        padding: 0 10px;
        vertical-align: middle;
        display: table-cell;
        p {
          word-wrap: break-word;
          line-height: 20px;
          white-space: normal;
          i {
            color: #31708f;
            font-weight: normal;
          }
        }
      }
      .sidebar_one_r {
        max-width: 100px;
        min-width: 100px;
        color: #14425d;
        padding: 0 5px;
        vertical-align: middle;
        display: table-cell;

        p {
          word-wrap: break-word;
          line-height: 20px;
          white-space: normal;
        }
      }

      &:last-child {
        border-bottom: 1px dashed transparent;
      }
    }
  }

  .sidebar_two {
    .sidebar_two_item {
      border-bottom: 1px dashed #ccc;
      display: flex;

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 40px;
      }
      .hot {
        border: 1px solid #fd8383;
        color: #fd8383;
        padding: 1px 3px;
        vertical-align: middle;
        margin-left: 5px;
      }
      .sidebar_two_l {
        flex: 1;
        .rightIcon {
          position: relative;
          margin-right: 16px;

          &::after {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            content: " ";
            background: url("~@/assets/img/backR.png") center center no-repeat;
            background-size: 100% 100%;
            width: 14px;
            height: 14px;
          }
        }
      }

      .sidebar_two_r {
        color: #14425d;
        border-left: 1px dashed #ccc;
        width: 40%;
      }

      &:last-child {
        border-bottom: 1px dashed transparent;
      }
    }
  }
}

.p-15 {
  padding: 0 5px;
}

.f_15 {
  font-size: 15px;
}

.border_l {
  padding: 0 15px;
  background-color: #ecf0f1;
  border: 1px solid #d9e1e4;
}
</style>