<template>
  <div class="index-container" :class="{ fixed_c: showSelect || showSelectT }">
    <m-nav :navInform="false" ref="nav" :page="'Home'"></m-nav>
    <!-- 最热 -->
    <div class="module">
      <div class="title-list f_16">
        <h2 class="title-list-l" @click="goTypeList(2, 5, 'Hot Novel')">
          Hot Novel
        </h2>
        <div class="input-container" @click="showSelect = !showSelect">
          <div class="input_class">{{ searchType }}</div>
          <van-icon
            class="icon_c"
            size="14"
            color="#9c8b6a"
            :name="!showSelect ? 'arrow-down' : 'arrow-up'"
          />
        </div>
      </div>
      <div class="index-intro">
        <div
          class="intro-img"
          v-for="(item, index) in hotList"
          :key="index"
          @click="goChapterList(item.novel_id)"
        >
          <img :src="item.novel_cover" :alt="item.novel_name" />
          <p class="img_title f_12">{{ item.novel_name }}</p>
        </div>
      </div>
    </div>
    <!-- 历史 -->
    <list-template
      :type="'two'"
      :data="historyList"
      class="m-b"
      :searchValue="readingTitle"
    ></list-template>
    <!-- 最新 -->
    <list-template
      :type="'two'"
      :data="newList"
      class="list_template"
      :searchValue="releaseTitle"
      @onNavList="goNavList"
    >
      <template #input>
        <div class="input-container" @click="showSelectT = !showSelectT">
          <div class="input_class">{{ searchTypeT }}</div>
          <van-icon
            class="icon_c"
            size="14"
            color="#9c8b6a"
            :name="!showSelectT ? 'arrow-down' : 'arrow-up'"
          />
        </div>
      </template>
    </list-template>
    <!-- 完结 -->
    <div class="module">
      <div class="title-list f_16">
        <h2
          class="title-list-l more_icon"
          @click="goTypeList(3, 5, 'Completed Novel')"
        >
          Novel Completed
        </h2>
      </div>
      <div class="intro_item">
        <div
          class="intro_img"
          v-for="(item, index) in completeList"
          :key="index"
          @click="goChapterList(item.novel_id)"
        >
          <img :src="item.novel_cover" :alt="item.novel_name" />
          <div class="img_title f_12">
            <p class="img_t">{{ item.novel_name }}</p>
            <span class="img_txt"> Full - {{ item.chapter_num }} chapters</span>
          </div>
        </div>
      </div>
    </div>
    <new-footer-m></new-footer-m>
    <!-- 选择弹框 -->
    <div
      class="dialog_box"
      v-if="showSelect || showSelectT"
      @click="clearDialog"
    >
      <div class="new_dialog" @click.stop>
        <div
          class="dialog_item"
          v-for="(item, index) in hotTypeList"
          :key="index"
          @click="onShowSelect(item)"
        >
          <span>{{ item.name }}</span>
          <span
            class="big_circle"
            :class="{
              active_dialog:
                (searchType == item.name && showSelect) ||
                (searchTypeT == item.name && showSelectT),
            }"
          >
            <div
              class="small_circle"
              v-if="
                (searchType == item.name && showSelect) ||
                (searchTypeT == item.name && showSelectT)
              "
            ></div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fictionLabel,
  fictionHot,
  fictionLatest,
  fictionCompleted,
} from "@/api/index.js";
import hotImg from "@/assets/img/hot.png";
import ListTemplate from "@/components/M/ListTemplate.vue";
import MNav from "@/components/M/MNav.vue";
import NewFooterM from "@/components/M/NewFooterM.vue";
export default {
  name: "Name",
  components: { MNav, NewFooterM, ListTemplate },
  metaInfo: {
    title: "Webnovelsite | Read Free Webnovels - Light Novels",
    meta: [
      {
        name: "title",
        content: "Webnovelsite | Read Free Webnovels - Light Novels",
      },
      {
        name: "description",
        content:
          "Webnovelsite is the foremost English  publisher of Chinese, Korean, Japanese and  English  webnovels and light novels. All thousands of novels are free and updated daily.",
      },
      {
        name: "keywords",
        content:
          "best webnovel, free webnovels, free books, novel updates, webnovel site, best light novels, webnovelsite",
      },
    ],
  },
  data() {
    return {
      historyList: [], //历史阅读记录
      newList: [], //最新小说
      hotList: [], //热门小说
      completeList: [], //完成小说
      hotTypeList: [], //小说类型选择
      hotImg: hotImg,
      searchType: "All", //选择类型
      searchTypeT: "All", //选择类型
      hotTitle: "Hot Novel",
      readingTitle: "Your novel reading", //历史阅读记录
      releaseTitle: "Latest Release", //最新版本
      showSelect: false, //类型选择框是否展开
      showSelectT: false, //类型选择框是否展开
      tagId: 0, //热门小说分类
      tagIdT: 0, //最新小说分类
    };
  },
  created() {
    this.getHotList();
    this.getNewList();
    this.getCompleteList();
    let typeList = this.$local.cacheGet("typeList");
    if (!typeList) {
      this.getTypeList();
    } else {
      this.hotTypeList = JSON.parse(JSON.stringify(typeList));
      this.hotTypeList.unshift({ id: 0, name: "All" });
    }
  },
  mounted() {
    // 如果本地存储的数据historyList有值，直接赋值给data中的historyList
    let array = JSON.parse(localStorage.getItem("historyList"));
    if (array) {
      this.historyList = array.splice(0, 5);
      localStorage.setItem("historyList", JSON.stringify(this.historyList));
    } else {
      localStorage.setItem("historyList", JSON.stringify([]));
    }
  },
  methods: {
    //热门小说
    getHotList() {
      fictionHot(1, 12, this.tagId).then((res) => {
        if (res.code == 200) {
          this.hotList = res.data.data;
        }
      });
    },
    // 最新小说
    getNewList() {
      fictionLatest(1, 20, this.tagIdT).then((res) => {
        if (res.code == 200) {
          this.newList = res.data.data;
        }
      });
    },
    // //完成小说
    getCompleteList() {
      fictionCompleted(1, 12).then((res) => {
        if (res.code == 200) {
          this.completeList = res.data.data;
        }
      });
    },
    // 小说类型
    getTypeList() {
      fictionLabel().then((res) => {
        if (res.code == 200) {
          let time = 24 * 60 * 60;
          this.$local.cacheSet("typeList", res.data, time);
          this.hotTypeList = JSON.parse(JSON.stringify(res.data));
          this.hotTypeList.unshift({ id: 0, name: "All" });
        }
      });
    },
    //关闭弹窗
    clearDialog() {
      if (this.showSelect) {
        this.showSelect = false;
      }
      if (this.showSelectT) {
        this.showSelectT = false;
      }
    },
    // 选择框是否展开
    onShowSelect(obj) {
      if (this.showSelect) {
        this.searchType = obj.name;
        this.tagId = obj.id;
        this.showSelect = false;
        this.getHotList();
      }
      if (this.showSelectT) {
        this.searchTypeT = obj.name;
        this.tagIdT = obj.id;
        this.showSelectT = false;
        this.getNewList();
      }
    },
    goNavList() {
      this.goTypeList(1, 5, "Latest Release");
    },
    // 去分类列表
    goTypeList(key, type, name) {
      name = name.replace(/\s/g, "-");
      this.$router.push(`/list/${name}`);
    },
    // 去小说详情
    goChapterList(id) {
      this.$router.push({
        path: `/articleList/${id}`,
      });
      // this.$emit("chapterList", id);
    },
  },
};
</script>
<style lang="scss" scoped>
.fixed_c {
  position: fixed;
}

.index-container {
  width: 100%;
  font-size: 14px;

  .m-b {
    margin-bottom: 15px;
  }

  .f_16 {
    font-size: 16px;
  }

  .module {
    .title-list {
      height: 40px;
      line-height: 40px;
      text-transform: uppercase;
      border-bottom: 1px solid #ccc;
      position: relative;
      display: flex;
      justify-content: space-between;

      div {
        cursor: default;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #4e4e4e;

        &:first-child {
          padding: 0 5px;
        }
      }

      .title-list-l {
        position: relative;
        margin-left: 5px;
        &::after {
          position: absolute;
          right: -15px;
          top: 50%;
          transform: translate(0, -50%);
          content: " ";
          background: url("~@/assets/img/hot.png") center center no-repeat;
          background-size: 100% 100%;
          width: 14px;
          height: 14px;
        }
      }

      .more_icon::after {
        background: url("~@/assets/img/backR.png") center center no-repeat;
        background-size: 100% 100%;
        width: 14px;
        height: 14px;
      }
    }

    .index-intro {
      margin-bottom: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .intro-img {
        margin-top: 7.5px;
        width: 78px;
        height: auto;
        position: relative;
        box-shadow: 0 1px 2px #b5b5b5;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }

        .img_title {
          padding: 5px 0;
          color: #f4f4f4;
          text-align: center;
          width: 100%;
          bottom: 0;
          position: absolute;
          background: #0000004b;
        }
      }
    }

    .intro_item {
      margin-bottom: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .intro_img {
        height: auto;
        margin: 7.5px 0;
        padding: 0 5px;
        width: 33.3%;
        text-align: center;

        img {
          padding: 5px;
          width: 100%;
        }

        .img_title {
          width: 100%;
          line-height: 18px;
          color: #3c3c3c;

          .img_t {
            text-align: center;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .img_txt {
            display: inline-block;
            padding: 0 2px;
            color: #fff;
            background-color: #428bca;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .input-container {
    font-size: 16px;
    padding: 0 5px;
    height: 40px;
    line-height: 40px;
    width: 150px;
    text-transform: uppercase;
    display: flex;

    .input_class {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon_c {
      line-height: 40px;
      width: 14px;
      font-size: 14px;
    }
  }

  .dialog_box {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000a3;

    .new_dialog {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 80%;
      width: 80%;
      background: #fff;
      overflow-y: auto;

      .dialog_item {
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #e7e7e7;
        position: relative;

        .big_circle {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1px solid #c4c5c9;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate(0, -50%);

          .small_circle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #445889;
          }
        }

        .active_dialog {
          border-color: #445889;
        }

        &:last-child {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
  .list_template .input-container {
    border-bottom: 1px solid #4e4e4e;
  }
}
</style>
<style scoped>
.list_template /deep/ .sidebar_two_item .sidebar_two_r {
  width: 25% !important;
}
.list_template /deep/ .sidebar_title {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.list_template /deep/ .sidebar_title_t {
  position: relative;
  display: block !important;
}
.list_template /deep/ .sidebar_title_t:after {
  content: " ";
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translate(0, -50%);
  background: url("~@/assets/img/backR.png") center center no-repeat;
  background-size: 100% 100%;
  width: 14px;
  height: 14px;
}
</style>
