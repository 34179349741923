<template>
  <div class="select-container">
    <div
      class="input-container"
      @click="arrowDown"
      :class="{ border_l: showSelect }"
    >
      <div class="input_class">{{ search }}</div>
      <van-icon
        class="icon icon_c"
        size="18"
        color="#9c8b6a"
        :name="!showSelect ? 'arrow-down' : 'arrow-up'"
      />
    </div>
    <div class="select-content" :class="{ active: showSelect }">
      <div
        v-for="(item, index) in list"
        class="select-line"
        :class="{ bgc_b: search == item.name }"
        @click="onSelect(item.name, item.id)"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomSelect",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    arrowDown() {
      this.$emit("onShowSelect", !this.showSelect);
    },
    onSelect(value, id) {
      this.$emit("changes", value, id);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-container {
  position: relative;
  font-size: 18px;

  .input-container {
    text-transform: uppercase;
    display: flex;
    width: 218px;
    height: 40px;
    line-height: 40px;
    .input_class {
      width: 180px;
      padding: 0 10px;
    }
    .icon_c {
      padding: 0 10px;
      line-height: 40px;
      width: 38px;
    }
  }

  .border_l {
    border: 1px solid #03a9f4;
  }
  .select-content {
    position: absolute;
    width: 218px;
    max-height: 540px;
    overflow-y: auto;
    left: 0;
    z-index: 99;
    display: none;
    border: 1px solid #cccccc;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    background-color: #f2f2f2;
    &.active {
      display: block;
      // animation: selectanim 0.25s;
      // -webkit-animation: selectanim 0.25s;
    }
    .bgc_b {
      background-color: #a6daee;
      color: #fff;
    }

    .select-line {
      padding: 0 5px;
      cursor: default;
      width: 100%;
      line-height: 30px;
    }

    @keyframes selectanim {
      from {
        opacity: 0;
        top: 80px;
      }

      to {
        opacity: 1;
        top: 40px;
      }
    }

    @-webkit-keyframes selectanim {
      from {
        opacity: 0;
        top: 80px;
      }

      to {
        opacity: 1;
        top: 40px;
      }
    }
  }
}
</style>