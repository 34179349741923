var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar_list f_14",class:{ border_l: _vm.borderShow, 'p-15': _vm.type == 'three' }},[_c('div',{staticClass:"sidebar_title f_16"},[_c('h2',{staticClass:"sidebar_title_t",on:{"click":_vm.goNavType}},[_vm._v(_vm._s(_vm.searchValue))]),_vm._t("input")],2),(_vm.type == 'one')?_c('div',{staticClass:"sidebar_one"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"sidebar_one_item"},[_c('div',{staticClass:"sidebar_one_l"},[_c('img',{attrs:{"src":item.novel_cover,"alt":item.novel_name}})]),_c('div',{staticClass:"sidebar_one_m"},[_c('span',{staticClass:"copy_icon"}),_c('span',{staticClass:"font_w",on:{"click":function($event){return _vm.goChapterList(item.novel_id)}}},[_vm._v(" "+_vm._s(item.novel_name)+" ")]),(item.hot == 1)?_c('span',{staticClass:"hot"},[_vm._v("Hot")]):_vm._e(),(item.full == 1)?_c('span',{staticClass:"full"},[_vm._v("Full")]):_vm._e(),_c('p',{staticClass:"author"},[_c('span',{staticClass:"pen_icon"}),_c('span',[_vm._v(_vm._s(item.novel_author))])])]),(item.last_chapter_name)?_c('div',{staticClass:"sidebar_one_r p-15"},[_c('p',{on:{"click":function($event){return _vm.goChapter(
              item.novel_id,
              item.chapter_id,
              item.last_chapter_name,
              item.novel_name
            )}}},[_vm._v(" "+_vm._s(item.last_chapter_name)+" ")])]):_vm._e(),(item.chapter_num)?_c('div',{staticClass:"sidebar_one_chapter"},[_c('p',[_c('span',[_vm._v(_vm._s(item.chapter_num)+" ")]),_vm._m(0,true)])]):_vm._e()])}),0):_vm._e(),(_vm.type == 'two')?_c('div',{staticClass:"sidebar_two"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"sidebar_two_item"},[_c('div',{staticClass:"sidebar_two_l p-15",on:{"click":function($event){return _vm.goChapterList(item.novel_id)}}},[_c('span',{staticClass:"rightIcon"}),_c('span',[_vm._v(_vm._s(item.novel_name))]),(item.hot)?_c('span',{staticClass:"hot"},[_vm._v("Hot")]):_vm._e()]),_c('div',{staticClass:"sidebar_two_r p-15",on:{"click":function($event){return _vm.goChapter(
            item.novel_id,
            item.chapter_id,
            item.last_chapter_name,
            item.novel_name
          )}}},[_vm._v(" "+_vm._s(item.last_chapter_name)+" ")])])}),0):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',[_vm._v("Chapters")])])
}]

export { render, staticRenderFns }