<template>
  <div class="index-container" @click.stop="closeSelect">
    <new-nav
      :navInform="false"
      ref="nav"
      :page="'Home'"
      :typeList="typeList"
    ></new-nav>
    <!-- 最热小说 -->
    <div class="module container">
      <div class="title-list f_20">
        <h2 class="title-list-l" @click="goNavList(2, 5, 'Hot Novel')">
          Hot Novel
        </h2>
        <div @click.stop>
          <custom-select
            :list="hotTypeList"
            :search="typeSelect"
            :showSelect="showSelect"
            @changes="changeType"
            @onShowSelect="onShowSelect"
          >
          </custom-select>
        </div>
      </div>
      <div class="index-intro clearDiv">
        <div
          class="intro-img"
          v-for="(item, index) in hotList"
          :key="index"
          @click="goChapterList(item.novel_id)"
        >
          <img :src="item.novel_cover" :alt="item.novel_name" />
          <p class="img_title">{{ item.novel_name }}</p>
        </div>
      </div>
    </div>
    <div class="fiction container">
      <div class="fiction_l">
        <list-template
          :type="'two'"
          :data="historyList"
          :searchValue="readingTitle"
          class="m-b"
        ></list-template>
        <list-template
          :type="'two'"
          class="list_template"
          :data="newList"
          :searchValue="releaseTitle"
          @onNavList="goNav"
          @onTypeList="goTypeList"
        >
          <template #input>
            <custom-select
              :list="hotTypeList"
              :search="typeSelectT"
              :showSelect="showSelectT"
              @changes="changeTypeT"
              @onShowSelect="onShowSelectT"
            >
            </custom-select>
          </template>
        </list-template>
      </div>
      <div class="fiction_r">
        <list-template
          :type="'two'"
          :data="historyList"
          class="m-b"
          :searchValue="readingTitle"
          :borderShow="true"
        ></list-template>
        <list-template
          :type="'four'"
          :data="typeList"
          :searchValue="typeTitle"
          :borderShow="true"
          @onTypeList="goTypeList"
        ></list-template>
      </div>
    </div>
    <!-- 完成小说 -->
    <div class="module container">
      <div class="title-list f_20">
        <h2
          class="title-list-l more_icon"
          @click="goNavList(3, 5, 'Completed Novel')"
        >
          Novel Completed
        </h2>
      </div>
      <div class="intro_item">
        <div
          class="intro_img"
          v-for="(item, index) in completeList"
          :key="index"
          @click="goChapterList(item.novel_id)"
        >
          <img :src="item.novel_cover" :alt="item.novel_name" />
          <div class="img_title">
            <p class="img_t">{{ item.novel_name }}</p>
            <span class="img_txt">Full - {{ item.chapter_num }} chapters</span>
          </div>
        </div>
      </div>
    </div>
    <new-footer></new-footer>
  </div>
</template>

<script>
import {
  fictionLabel,
  fictionHot,
  fictionLatest,
  fictionCompleted,
} from "@/api/index.js";
import NewNav from "@/components/pc/NewNav.vue";
import NewFooter from "@/components/pc/NewFooter.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import ListTemplate from "@/components/pc/ListTemplate.vue";
export default {
  components: {
    NewNav,
    NewFooter,
    CustomSelect,
    ListTemplate,
  },
  name: "Home",
  metaInfo: {
    title: "Webnovelsite | Read Free Webnovels - Light Novels",
    meta: [
      {
        name: "title",
        content: "Webnovelsite | Read Free Webnovels - Light Novels",
      },
      {
        name: "description",
        content:
          "Webnovelsite is the foremost English  publisher of Chinese, Korean, Japanese and  English  webnovels and light novels. All thousands of novels are free and updated daily.",
      },
      {
        name: "keywords",
        content:
          "best webnovel, free webnovels, free books, novel updates, webnovel site, best light novels, webnovelsite",
      },
    ],
  },
  data() {
    return {
      historyList: [], //历史阅读记录
      newList: [], //最新小说
      hotList: [], //热门小说
      completeList: [], //完成小说
      typeList: [], //小说类型
      hotTypeList: [], //小说类型选择
      typeSelect: "all", //热门选择类型
      typeSelectT: "all", //最新选择类型
      showSelect: false, //热门选择框是否展开
      showSelectT: false, //最新选择框是否展开
      readingTitle: "Your novel reading", //历史阅读记录
      releaseTitle: "Latest Release", //最新版本
      typeTitle: "Genres", //小说流派
      tagId: 0, //热门小说分类
      tagIdT: 0, //最新小说分类
    };
  },
  created() {
    this.getHotList();
    this.getNewList();
    this.getCompleteList();
    let typeList = this.$local.cacheGet("typeList");
    if (!typeList) {
      this.getTypeList();
    } else {
      this.typeList = typeList;
      this.hotTypeList = JSON.parse(JSON.stringify(typeList));
      this.hotTypeList.unshift({ id: 0, name: "All" });
    }
  },
  mounted() {
    // 如果本地存储的数据historyList有值，直接赋值给data中的historyList
    let array = JSON.parse(localStorage.getItem("historyList"));
    if (array) {
      this.historyList = array.splice(0, 5);
      localStorage.setItem("historyList", JSON.stringify(this.historyList));
    } else {
      localStorage.setItem("historyList", JSON.stringify([]));
    }
    window.addEventListener("scroll", this.scrollToTop);
  },
  methods: {
    //热门小说
    getHotList() {
      fictionHot(1, 13, this.tagId).then((res) => {
        // console.log(res, "热门小说");
        if (res.code == 200) {
          this.hotList = res.data.data;
        }
      });
    },
    // 最新小说
    getNewList() {
      fictionLatest(1, 20, this.tagIdT).then((res) => {
        // console.log(res, "最新小说");
        if (res.code == 200) {
          this.newList = res.data.data;
        }
      });
    },
    // //完成小说
    getCompleteList() {
      fictionCompleted(1, 12).then((res) => {
        // console.log(res, "完成小说");
        if (res.code == 200) {
          this.completeList = res.data.data;
        }
      });
    },
    // 小说类型
    getTypeList() {
      fictionLabel().then((res) => {
        if (res.code == 200) {
          let time = 24 * 60 * 60;
          this.$local.cacheSet("typeList", res.data, time);
          this.typeList = res.data;
          this.hotTypeList = JSON.parse(JSON.stringify(res.data));
          this.hotTypeList.unshift({ id: 0, name: "All" });
        }
      });
    },
    //热门切换类型
    changeType(value, id) {
      if (this.typeSelect !== value) {
        this.typeSelect = value;
        this.tagId = id;
        this.showSelect = false;
        this.getHotList();
      } else {
        this.showSelect = false;
      }
    },
    //热门选择框是否展开
    onShowSelect(value) {
      this.showSelect = value;
    },
    //最新切换类型
    changeTypeT(value, id) {
      if (this.typeSelectT !== value) {
        this.typeSelectT = value;
        this.tagIdT = id;
        this.showSelectT = false;
        this.getNewList();
      } else {
        this.showSelectT = false;
      }
    },
    //最新选择框是否展开
    onShowSelectT(value) {
      this.showSelectT = value;
    },
    // 页面滚动
    scrollToTop() {
      if (this.showSelect) {
        this.showSelect = false;
      }
    },
    // 关闭选择框
    closeSelect() {
      this.showSelect = false;
      this.$refs.nav.clearSelect();
    },
    goNav() {
      this.goNavList(1, 5, "Latest Release");
    },
    goNavList(key, type, name) {
      name = name.replace(/\s/g, "-");
      this.$router.push(`/list/${name}`);
    },
    // 去分类列表
    goTypeList(key, type, name) {
      name = name.replace(/\s/g, "-");
      this.$router.push({
        path: `/genre/${key}`,
        query: { type: name },
      });

      // this.$store.commit("set_bookInfo", obj);
    },
    // 去小说详情
    goChapterList(id) {
      this.$router.push({
        name: "ArticleList",
        params: { id },
      });
      // this.$emit("chapterList", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.index-container {
  width: 100%;
  .clearDiv::after {
    content: ""; //使div中有内容但是不需要具体内容就相当于占位
    visibility: hidden; //为了防止误操作使div中存在内容，造成排版混乱此操作进行内容隐藏
    height: 0px; //以免一些操作使此div存在行高将此行高设置为默认值0px；
    display: block; //进行块级显示
    clear: both; //清除浮动
  }
  .f_20 {
    font-size: 20px;
  }
  .m-b {
    margin-bottom: 15px;
  }
  .module {
    .title-list {
      height: 40px;
      line-height: 40px;
      text-transform: uppercase;
      border-bottom: 1px solid #ccc;
      position: relative;
      display: flex;
      justify-content: space-between;
      div {
        cursor: default;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #4e4e4e;
      }

      .title-list-l {
        cursor: default;
        position: relative;
        font-size: 20px;
        &::after {
          position: absolute;
          // right: -20px;
          top: 50%;
          transform: translate(0, -50%);
          content: " ";
          background: url("~@/assets/img/hot.png") center center no-repeat;
          background-size: 100% 100%;
          width: 16px;
          height: 16px;
        }
      }
      .more_icon::after {
        background: url("~@/assets/img/backR.png") center center no-repeat;
        background-size: 100% 100%;
        width: 16px;
        height: 16px;
      }
    }

    .index-intro {
      cursor: pointer;
      margin-bottom: 15px;
      box-sizing: border-box;
      max-height: 408px;
      overflow: hidden;
      .intro-img {
        width: 129px;
        height: 192px;
        margin: 12px 0 0 12px;
        float: left;
        position: relative;
        display: block;
        transition: all 0.1s ease-in-out;
        box-shadow: 0 1px 2px #b5b5b5;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }

        .img_title {
          padding: 5px 0;
          color: #f4f4f4;
          text-align: center;
          width: 100%;
          bottom: 0;
          position: absolute;
          background: #0000004b;
        }
        &:first-child {
          width: 265px;
          height: 396px;
          .img_title {
            padding: 10px 0;
            font-size: 20px;
            font-weight: 700;
            color: #fffc57;
            text-shadow: 1px 2px 2px #000;
          }
        }
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .intro_item {
      cursor: pointer;
      margin-bottom: 15px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      max-height: 626px;
      overflow: hidden;
      .intro_img {
        margin: 7.5px 0;
        width: calc(100% / 6);

        img {
          margin: 0 auto;
          display: block;
          width: 164px;
          height: 245px;
        }

        .img_title {
          padding: 5px;
          text-align: center;
          width: 100%;
          text-align: center;
          line-height: 18px;
          color: #3c3c3c;
          .img_t {
            padding: 2px;
            font-size: 16px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .img_txt {
            display: inline-block;
            padding: 1px 5px 2px;
            color: #fff;
            background-color: #428bca;
            border-radius: 3px;
          }
        }
      }
    }
  }
  .fiction {
    display: flex;
    justify-content: space-between;
    .fiction_l {
      width: 815px;
    }
    .fiction_r {
      margin-left: 15px;
      width: 310px;
      .q {
        background-color: #ecf0f1;
        border: 1px solid #d9e1e4;
      }
    }
  }
  .list_template .select-container {
    border-bottom: 1px solid #4e4e4e;
  }
}
</style>
<style scoped>
.list_template /deep/ .sidebar_two_item .sidebar_two_r {
  width: 20% !important;
}
.list_template /deep/ .sidebar_title {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.list_template /deep/ .sidebar_title_t {
  position: relative;
  display: block !important;
}
.list_template /deep/ .sidebar_title_t:after {
  content: " ";
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translate(0, -50%);
  background: url("~@/assets/img/backR.png") center center no-repeat;
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
}
</style>
