import { render, staticRenderFns } from "./CustomSelect.vue?vue&type=template&id=590064ae&scoped=true&"
import script from "./CustomSelect.vue?vue&type=script&lang=js&"
export * from "./CustomSelect.vue?vue&type=script&lang=js&"
import style0 from "./CustomSelect.vue?vue&type=style&index=0&id=590064ae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "590064ae",
  null
  
)

export default component.exports